<template>
  <pre>
8888888b.                        .d8888b.  
888   Y88b                      d88P  Y88b 
888    888                      Y88b. d88P 
888   d88P888d888 .d88b.  .d8888b"Y88888"  
8888888P" 888P"  d8P  Y8bd88P"  .d8P""Y8b. 
888       888    88888888888    888    888 
888       888    Y8b.    Y88b.  Y88b  d88P 
888       888     "Y8888  "Y8888P"Y8888P"  
                                                                               
</pre
  >
  <!-- <img alt="Vue logo" src="./assets/logo.png" />
  <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" /> -->

  <RouterView />
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";

@Options({
  components: {},
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
