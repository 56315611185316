import { createApp } from "vue";
import App from "./App.vue";
import Home from "./Home.vue";
import InstallSuccess from "./InstallSuccess.vue";
import SignIn from "./SignIn.vue";
import {
  createWebHistory,
  createRouter,
  createWebHashHistory,
} from "vue-router";

const routes = [
  { path: "/", component: Home },
  { path: "/install-success", component: InstallSuccess },
  { path: "/sign-in", component: SignIn },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
