<template>
  <p>HOME</p>
  <div>
    <button @click="signIn">Sign In</button>
  </div>
  <p>{{ figletText }}</p>
  <p>
    <a
      :href="addToSlackUrl"
      style="
        align-items: center;
        color: #000;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 56px;
        display: inline-flex;
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 600;
        height: 56px;
        justify-content: center;
        text-decoration: none;
        width: 276px;
      "
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        style="height: 24px; width: 24px; margin-right: 12px"
        viewBox="0 0 122.8 122.8"
      >
        <path
          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
          fill="#e01e5a"
        ></path>
        <path
          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
          fill="#36c5f0"
        ></path>
        <path
          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
          fill="#2eb67d"
        ></path>
        <path
          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
          fill="#ecb22e"
        ></path></svg
      >Add to Slack</a
    >
  </p>
  <!-- <img alt="Vue logo" src="./assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" /> -->
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";

@Options({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  figletText = "";
  addToSlackUrl = "";

  signIn() {
    this.$router.push("/sign-in");
  }

  mounted() {
    let installRedirectUri =
      process.env.VUE_APP_DEV_INSTALL_REDIRECT_URI ||
      "https://chat.prec8.com/install-callback";
    let slackClientId =
      process.env.VUE_APP_SLACK_CLIENT_ID || "5302144459057.7245506041362";
    this.addToSlackUrl =
      "https://slack.com/oauth/v2/authorize?client_id=" +
      slackClientId +
      "&scope=channels:history,channels:join,channels:read,chat:write,commands,groups:history,groups:read,im:history,im:read,im:write,links:read,mpim:history,mpim:read,reactions:read,team:read,users:read,users:read.email,users:write,app_mentions:read,emoji:read&user_scope=channels:read,channels:write,groups:read,groups:write,users:read&redirect_uri=" +
      installRedirectUri;
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
